<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
            @change="updatePerPage"
          />
        </b-col>

        <!-- Filter by Status -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end"
        >
          <b-form-select
            v-model="filterStatus"
            :options="statusSelect"
            label="name"
            :clearable="false"
            class="mb-0 pr-0 item-selector-title"
            :placeholder="$t('common.select_item')"
            @input="searchInvoices"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="5"
        >

          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search')"
              @keyup="searchInvoices"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refInvoiceListTable"
      :items="filterInvoices"
      responsive
      :fields="headers"
      primary-key="invoice_id"
      :sort-by.sync="sortBy"
      :per-page="0"
      show-empty
      :empty-text="$t('invoice.no_result')"
      :sort-desc.sync="isSortDirDesc"
      :busy="isBusy"
      :current-page="currentPage"
      class="position-relative rows-responsive"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>

      <template #head(status)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`invoice-row-${data.item.invoice_id}`"
          size="32"
          :variant="
            `light-${
              resolveInvoiceStatusVariantAndIcon(data.item.status).variant
            }`
          "
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.invoice_id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ getStatusTranslate(data.item.status) }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.invoices_updated_date }}
          </p>
        </b-tooltip>
      </template>

      <template #cell(invoice_id)="data">
        <b-link
          :to="{
            name: 'drs-invoice-preview',
            params: { id: data.item.invoice_id },
          }"
          :class="data.item.total < 0 ? 'font-weight-bold text-danger' : 'font-weight-bold'"
        >
          {{ data.item.code }}
        </b-link>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.customer_name }}
          </span>
          <small>{{ data.item.city }} - {{ data.item.cp }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.item.invoices_date }}
        </span>
      </template>

      <!-- Column: Total -->
      <template #cell(total)="data">
        <span :class="data.item.total < 0 ? 'text-danger' : ''">{{ formatNumberEuro(data.item.total) }} €</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-button
          :to="{
            name: 'drs-invoice-preview',
            params: { id: data.item.invoice_id },
          }"
          variant="info"
          class="btn-icon mrbt"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
          />
        </b-button>
        <b-button
          variant="success"
          class="btn-icon mrbt"
          @click="printInvoice(data.item.invoice_id, data.item.code)"
        >
          <feather-icon
            icon="PrinterIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-if="data.item.total > 0 && isAdmin && data.item.status !== 'CANCELLED'"
          variant="danger"
          class="btn-icon"
          @click="cancelInvoice(data.item.invoice_id)"
        >
          <feather-icon
            icon="FileMinusIcon"
            size="16"
          />
        </b-button>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >{{ $t("common.displaying") }}
            {{
              perPage * currentPage > totalItems
                ? totalItems
                : perPage * currentPage
            }}
            {{ $t("common.of") }} {{ totalItems }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-if="renderPagination"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BButton,
  BPagination,
  BFormSelect,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import dbInvoice from '@/models/invoices'
import useInvoicesList from './useInvoicesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BButton,
    BPagination,
    BTooltip,
    BSpinner,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      renderPagination: true,
      invoices: [],
      search: '',
      status: '',
      filter: null,
      filterStatus: '',
      statusTranslated: '',
      isBusy: false,
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalItems: null,
      headers: [],
      fields: [
        { key: 'status', sortable: false },
        { key: 'invoice_id', label: 'invoice.invoice_number', sortable: false },
        { key: 'client', label: 'budget.client', sortable: false },
        { key: 'total', label: 'budget.total', sortable: false },
        { key: 'issuedDate', label: 'budget.invoices_date', sortable: false },
        { key: 'actions', label: 'common.actions', sortable: false },
      ],
      statusSelect: [
        { value: '', text: 'Alles laten zien status' },
        { value: 'PENDING', text: 'NOG TE BETALEN' },
        { value: 'PAID', text: 'BETAALD' },
        { value: 'NOT PAID', text: 'NIET BETAALD' },
        { value: 'CANCELLED', text: 'GEANNULEERD' },
        { value: 'REMINDER', text: 'HERRINERING' },
      ],
    }
  },
  computed: {
    filterInvoices() {
      return this.invoices.filter(
        i => i.customer_name.toLowerCase().includes(this.search.toLowerCase())
          || i.code.toLowerCase().includes(this.search.toLowerCase())
          || i.city.toLowerCase().includes(this.search.toLowerCase())
          || i.invoices_date.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    isAdmin() {
      return (
        JSON.parse(window.localStorage.userData).role === 'ADMIN'
        || JSON.parse(window.localStorage.userData).role === 'ADMINISTRATION'
      )
    },
  },
  watch: {
    currentPage() {
      this.fetch(this.currentPage)
    },
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetch(1)
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  mounted() {
    this.fieldLanguage()
  },
  methods: {
    async printInvoice(id, code) {
      await dbInvoice.printInvoice(id)
      const urlPDF = `${process.env.VUE_APP_BACKEND_URL}/pdfs/${code}.pdf`
      window.open(urlPDF)
    },
    searchInvoices() {
      if (this.search.length > 3 || this.search.length === 0 || this.filterStatus !== null) this.fetch(1)
    },
    cancelInvoice(id) {
      this.$bvModal.msgBoxConfirm(this.$t('invoice.modal.body_cancel_invoice'), {
        title: this.$t('invoice.modal.tit_cancel_invoice'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.confirmationCancelInvoice(id)
          }
        })
    },
    async confirmationCancelInvoice(id) {
      await dbInvoice.cancelInvoice(id)
      this.fetch(1)
    },
    // eslint-disable-next-line consistent-return
    getStatusTranslate(status) {
      const getOptionStatus = Object.values(this.statusSelect)
      if (!getOptionStatus) {
        const statusDutch = getOptionStatus.find(i => i.value === status)
        // eslint-disable-next-line prefer-destructuring
        this.statusTranslated = Object.values(statusDutch)[1]
        return this.statusTranslated
      }
    },
    fieldLanguage() {
      this.headers = JSON.parse(JSON.stringify(this.fields))
      this.fields.forEach((header, index) => {
        if (header.label) {
          this.headers[index].label = `${this.$i18n.t(this.fields[index].label)}`
        }
      })
    },
    updatePerPage(size) {
      this.perPage = size
      this.fetch(1)
    },
    async fetch(page) {
      this.isBusy = true
      const response = await dbInvoice.getAll({
        page,
        perPage: this.perPage,
        paginate: true,
        word: this.search,
        status: this.filterStatus,
      })
      this.invoices = response.data
      this.totalItems = response.total
      this.isBusy = false
      this.renderPagination = false
      this.$nextTick(() => {
        this.renderPagination = true
      })
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
  setup() {
    const {
      tableColumns,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      // statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      tableColumns,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      refetchData,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
